<template>
  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >

          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchKey"
                class="d-inline-block mr-1"
                placeholder="搜索关键字..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchListAll"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
        select-mode="single"
        selectable
        @row-selected="onRowSelected"
      >

        <!-- Columns -->
        <template #cell(warehouse_id)="data">
            #{{ data.item.warehouse_id }}
        </template>



        <!-- Column: creator checker -->

          <template #cell(check_time)="data">
              {{toTime(data.item.check_time)}}
          </template>

          <template #cell(creator)="data">
              {{getCodeLabel('user', data.item.creator)}}
          </template>

          <template #cell(updator)="data">
              {{getCodeLabel('user', data.item.updator)}}
          </template>

          <template #cell(checker)="data">
              {{getCodeLabel('user', data.item.checker)}}
          </template>

          <template #cell(status)="data">
              {{getCodeLabel('warehouse_status', data.item.status)}}
          </template>

          <template #cell(attachments)="data">
              <attachment-upload :theme="'preview-dropdown'"
                                 :attachment_id="'attachments'"
                                 :id="data.item.attachments"
                                 :object_type="'warehouse'"
                                 :object_id="0"
                                 :readonly="true"
                                 @change=""
              />
          </template>




        <!-- Column: Actions   style=" margin-left: -47px;"-->
        <template #cell(actions)="data" >
          <b-dropdown
            variant="link"
            no-caret
            left="-47px "
          >

<!--     style=" margin-left: 30px;"        -->
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

              <b-dropdown-item  @click="submitSuccess(data.item)" >
                  <feather-icon icon="EditIcon"/>
                  <span class="align-middle ml-50">审核通过</span>
              </b-dropdown-item>

              <b-dropdown-item  @click="submitFailed(data.item)" >
                  <feather-icon icon="EditIcon"/>
                  <span class="align-middle ml-50">审核失败</span>
              </b-dropdown-item>

              <b-dropdown-item v-b-modal.modal-warehouse-log @click="changeModifyLog(data.item)" >
                  <feather-icon icon="EditIcon"/>
                  <span class="align-middle ml-50">修改日志</span>
              </b-dropdown-item>


          </b-dropdown>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

        <b-modal
                id="modal-warehouse-log"
                hide-footer
                centered
                size="xl"
                title="修改日志"
        >
            <modify-log-list :objectTypeProduct="2" :productid="id"  ></modify-log-list>
        </b-modal>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText,getCodeLabel,toTime,getCodeColor } from '@core/utils/filter'
import warehouseUseList from './warehouseUseList'
import companyStore from "@/views/apps/company/companyStore";
import warehouseStore from '@/views/apps/warehouse/warehouseStore'
import { useRouter } from '@core/utils/utils'
import ModifyLogList from "@/views/apps/modifylog/ModifyLogList";
import {useToast} from "vue-toastification/composition";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BAlert,
    ModifyLogList,
    AttachmentUpload
  },
  data(){
    return{
      id:0,
      companyName:"",
      companyInformation:{},
      selected: [],
    }
  },
  props:{
    company_id:{
      type:Number,
      default:0,
    }
  },
  methods: {

    onRowSelected(items) {
      this.selected = items
    },
      changeModifyLog(data) {
          this.id=data.warehouse_id
      },
  },
  setup(props) {
    // Register module
    if (!store.hasModule('warehouse')) store.registerModule('warehouse', warehouseStore)
    if (!store.hasModule('company')) store.registerModule('company', companyStore)

    onUnmounted(() => {
      if (store.hasModule('warehouse')) store.unregisterModule('warehouse')
      if (store.hasModule('company')) store.unregisterModule('company')
    })

      const toast = useToast()
    const router = useRouter()


    const submitSuccess = function (data) {
        let status = 4
        let state = 1
        store.dispatch('warehouse/submit', {id: data.id,state:state, status: status }).then(res => {
            if(res.data.code===0){
                toast.success("操作成功")
                refetchData()
            }
        })

    }

      const submitFailed = function (data) {
          let status = 5
          let state = 0
          store.dispatch('warehouse/submit', {id: data.id,state:state, status: status }).then(res => {
               if(res.data.code===0){
                   toast.success("操作成功")
                   refetchData()
               }
          })
      }



      const {
      searchList,
      searchListAll,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = warehouseUseList()

    return {
      searchList,
      searchListAll,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      avatarText,
      // UI
      getCodeLabel, toTime,getCodeColor,
        submitSuccess,
        submitFailed,
        toast


    }
  },
  created() {


  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
